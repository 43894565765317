import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { ServicesApi } from "../../../apis";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles({
  inputLabels: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#31394D",
    textAlign: "left",
    marginBottom: "10px",
  },
});

const AddService = () => {
  const { id } = useParams();
  const isEditMode = !!id;
  const navigate = useNavigate();
  const [mediaPreview, setMediaPreview] = useState(null);
  const [service, setService] = useState(null);

  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    media: yup.mixed().required("Image or Video is required"),
  });

  const classes = useStyles();

  useEffect(() => {
    if (isEditMode) {
      ServicesApi.getServiceById(id)
        .then((res) => {
          const serviceData = res.data;
          setService(serviceData);

          formik.setFieldValue("name", serviceData.data.name);
          formik.setFieldValue(
            "media",
            serviceData.data.image || serviceData.data.video
          );

          // Set media preview
          if (serviceData.data.image) {
            setMediaPreview({
              url: `${process.env.REACT_APP_BASE_URL}/images/${serviceData.data.image}`,
              isVideo: false,
            });
          } else if (serviceData.data.video) {
            setMediaPreview({
              url: `${process.env.REACT_APP_BASE_URL}/videos/${serviceData.data.video}`,
              isVideo: true,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching service:", error);
        });
    }
  }, [id, isEditMode]);

  const isVideoFile = (file) => file?.type.startsWith("video");

  const formik = useFormik({
    initialValues: {
      name: isEditMode && service ? service.name : "",
      media: null,
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      if (values.media instanceof File) {
        formData.append("file", values.media);
      }

      if (isEditMode) {
        ServicesApi.updateService(id, formData)
          .then(() => {
            navigate("/services");
          })
          .catch((error) => {
            console.error("Error updating service:", error);
          });
      } else {
        ServicesApi.addServices(formData)
          .then(() => {
            navigate("/services");
          })
          .catch((error) => {
            console.error("Error adding service:", error);
          });
      }
    },
  });

  const handleMediaChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setMediaPreview({
        url: URL.createObjectURL(file),
        isVideo: isVideoFile(file),
      });
      formik.setFieldValue("media", file);
    }
  };

  const handleMediaDelete = () => {
    setMediaPreview(null);
    formik.setFieldValue("media", "");
  };

  return (
    <Box sx={{ background: "#FFFFFF", borderRadius: "14px", padding: "30px" }}>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box display={"flex"}>
            <ArrowBackIcon onClick={() => navigate("/services")} />
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "16px" }}>
              {isEditMode ? "Edit Service" : "Add Service"}
            </Typography>
          </Box>
          <Box pt={3}>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </Box>
        </Box>

        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Name:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Image/Video:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <input
                  type="file"
                  accept="image/*,video/*"
                  onChange={handleMediaChange}
                  style={{ display: "none" }}
                  id="media-upload"
                />
                <label htmlFor="media-upload">
                  <Button variant="outlined" component="span">
                    Upload Media
                  </Button>
                </label>

                {mediaPreview && (
                  <Box mt={2} position="relative">
                    {mediaPreview.isVideo ? (
                      <video width="300" height="200" controls>
                        <source src={mediaPreview.url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        src={mediaPreview.url}
                        alt="Uploaded media preview"
                        width={300}
                        height={200}
                      />
                    )}
                    <IconButton onClick={handleMediaDelete}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default AddService;
