import axios from "../axiosConfig";

class Routes {
  // get all services
  getServices = () => {
    let data = axios.get("/api/service/get");
    return data;
  };
  // get service by id
  getServiceById = (id) => {
    let data = axios.get(`/api/service/edit/${id}`);
    return data;
  };
  // /add-service/detail
  addServices = (data) => {
    let response = axios.post("/api/service/create", data);
    return response;
  };
  // update service by id
  updateService = (id, data) => {
    let response = axios.put(`/api/service/update-service/${id}`, data);
    return response;
  };
  deleteService = (id) => {
    let response = axios.delete(`/api/service/delete-service/${id}`);
    return response;
  };
  // get service detail
  getServicesDetail = (id) => {
    let data = axios.get(`/api/service/get-service-details/${id}`);
    return data;
  };
  getServiceDetailById = (id) => {
    let data = axios.get(`/api/service/view-service-details/${id}`);
    return data;
  };
  // /add-service/detail
  addServicesDetail = (formData) => {
    let response = axios.post("/api/service/create-service-details", formData);
    return response;
  };

  // update service details by id
  updateServiceDetails = (id, data) => {
    let response = axios.put(`/api/service/update-service-details/${id}`, data);
    return response;
  };
  // Delete service details by ID
  deleteServiceDetails = (id) => {
    let response = axios.delete(`/api/service/delete-service-details/${id}`);
    return response;
  };

  // deleteImage
  deleteImage = (id, data) => {
    let response = axios.post(`/api/service/delete-service-detail-image`, {
      id: id,
      image: data.image,
    });
    return response;
  };
}

export default new Routes();
