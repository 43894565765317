import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, ButtonGroup, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { ServicesApi } from "../../../apis";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";

function Services() {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);

  const fetchData = () => {
    ServicesApi.getServices()
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching service:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRowClick = (id) => {
    navigate(`/services/detail/${id}`);
  };
  const handleDelete = (id) => {
    // Call the deleteService function passing the id
    ServicesApi.deleteService(id)
      .then(() => {
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
      });
  };
  return (
    <Box
      sx={{
        background: "#FFFFFF",
        borderRadius: "14px",
        padding: "30px",
      }}
    >
      <div className="table-responsive" style={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "20px",
              }}
            >
              View Services
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={() => navigate("/add-services")}
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              background: "secondary.main",
              borderRadius: "5px",
              border: "none",
              boxShadow: "none",
              color: "#FFFFFF",
              outline: "none",
              "&:hover": {
                background: "secondary.main",
                outline: "none",
              },
            }}
          >
            Add Service
          </Button>
        </Box>
        <Grid container spacing={2} pt={5}>
          <Grid xs={12}>
            <table className="table">
              <thead>
                <tr>
                  <th style={{ paddingRight: "20px" }}>Service ID</th>
                  <th style={{ paddingLeft: "40px" }}>Media</th>
                  <th style={{ paddingLeft: "40px" }}>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.length === 0 && (
                  <tr>
                    <td colSpan="4" style={{ textAlign: "center" }}>
                      No data found
                    </td>
                  </tr>
                )}
                {data.length > 0 &&
                  data.map((service, index) => (
                    <tr key={index} onClick={() => handleRowClick(service.id)}>
                      <td style={{ paddingLeft: "40px" }}>{service.id}</td>
                      <td style={{ paddingLeft: "40px" }}>
                        {console.log("ffgh0", service.image.endsWith(".mp4"))}
                        {service.image && service.image.endsWith(".mp4") ? (
                          <video
                            controls
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                          >
                            <source
                              src={`${process.env.REACT_APP_BASE_URL}/images/${service.image}`}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          service.image && (
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}/images/${service.image}`}
                              alt={service.name}
                              style={{ maxWidth: "100px", maxHeight: "100px" }}
                            />
                          )
                        )}
                      </td>
                      <td style={{ paddingLeft: "40px" }}>{service.name}</td>
                      <td>
                        <ButtonGroup
                          variant="text"
                          aria-label="large button group"
                          sx={{ paddingLeft: "auto" }}
                        >
                          <Button
                            style={{ color: "grey" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              navigate(`/edit-services/${service.id}`);
                            }}
                          >
                            <BorderColorIcon />
                          </Button>
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(service.id);
                            }}
                          >
                            <DeleteIcon color="error" />
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default Services;
