import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useFormik } from "formik";
import {
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { ServicesApi } from "../../../apis";
import { makeStyles } from "@mui/styles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';

const useStyles = makeStyles({
  inputLabels: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#31394D",
    textAlign: "left",
  },
});

// const AddServiceDetails = () => {
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const isEditMode = !!id;
//   // useSearchParams
//   const [searchParams] = useSearchParams();

//   const [image, setImage] = useState(null);
//   const [imagePreview, setImagePreview] = useState(null);
//   const [addOns, setAddOns] = useState([{ key: "", price: "" }]);
//   const [editorState, setEditorState] = useState("");
//   const [service, setService] = useState(null); // State to store service data

//   const validationSchema = yup.object({
//     serviceId: yup.string().required("Service ID is required"),
//     price: yup.number().positive(),
//     title: yup.string().required("Title is required"),
//     description: yup.string().required("Description is required"),
//   });
//   useEffect(() => {
//     if (searchParams.get("serviceId") !== null) {
//       // Fetch existing service details
//       ServicesApi.getServiceDetailById(id).then((res) => {
//         const serviceData = res.data;
//         console.log(res.data);
//         setService(serviceData);
//         formik.setFieldValue("price", res.data.data.price);
//         formik.setFieldValue("title", res.data.data.title);
//         formik.setFieldValue("description", res.data.data.description);
//         formik.setFieldValue("image", res.data.data.image);
//       });
//     }
//   }, [id]); // Fetch details when id changes

//   const classes = useStyles();

//   const formik = useFormik({
//     initialValues: {
//       serviceId: id,
//       price: "",
//       title: "",
//       description: "",
//       // addOns: addOns,
//       image: null, // Update to handle single image field correctly
//     },
//     validationSchema: validationSchema,
//     onSubmit: (values) => {
//       const formData = new FormData();
//       formData.append("serviceId", values.serviceId);
//       formData.append("price", values.price);
//       formData.append("title", values.title);
//       formData.append("description", values.description);

//       // Check and append image if it's a valid file
//       if (values.image instanceof File) {
//         formData.append("image", values.image);
//       }

//       let serviceID = searchParams.get("serviceId");
//       if (serviceID) {
//         ServicesApi.updateServiceDetails(id, formData).then(() => {
//           navigate(`/services/detail/${serviceID}`, { replace: true });
//         });
//       } else {
//         ServicesApi.addServicesDetail(formData).then(() => {
//           navigate(`/services/detail/${id}`, { replace: true });
//         });
//       }
//     },
//   });

//   const handleImageChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setImage(file);
//       setImagePreview(URL.createObjectURL(file));
//       formik.setFieldValue("image", file);
//     }
//   };

//   const handleImageDelete = () => {
//     setImage(null);
//     setImagePreview(null);
//     formik.setFieldValue("image", null); // Reset the image field correctly
//   };

//   return (
//     <Box
//       sx={{
//         background: "#FFFFFF",
//         borderRadius: "14px",
//         padding: "30px",
//       }}
//     >
//       <form onSubmit={formik.handleSubmit}>
//         <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//           <Box display={"flex"}>
//             <ArrowBackIcon
//               onClick={() => {
//                 let backId =
//                   searchParams.get("serviceId") !== null
//                     ? searchParams.get("serviceId")
//                     : id;
//                 navigate(`/services/detail/${backId}`, { replace: true });
//               }}
//             />
//             <Typography ml={1} sx={{ fontWeight: "600", fontSize: "16px" }}>
//               {searchParams.get("serviceId") !== null ? "Edit" : "Add"} Service
//               Details
//             </Typography>
//           </Box>
//           <Box pt={3}>
//             <Button
//               type="submit"
//               variant="contained"
//               sx={{
//                 fontWeight: 500,
//                 fontSize: "14px",
//                 background: "secondary.main",
//                 borderRadius: "5px",
//                 border: "none",
//                 boxShadow: "none",
//                 color: "#FFFFFF",
//                 outline: "none",
//                 "&:hover": {
//                   background: "secondary.main",
//                   outline: "none",
//                 },
//               }}
//             >
//               Save
//             </Button>
//           </Box>
//         </Box>

//         <Box>
//           <Grid container spacing={2}>
//             <Grid item xs={6}>
//               <InputLabel mt={2} className={classes.inputLabels}>
//                 Price:
//               </InputLabel>
//               <FormControl sx={{ width: "100%" }}>
//                 <TextField
//                   fullWidth
//                   id="price"
//                   name="price"
//                   type="number"
//                   value={formik.values.price}
//                   onChange={formik.handleChange}
//                   error={formik.touched.price && Boolean(formik.errors.price)}
//                   helperText={formik.touched.price && formik.errors.price}
//                   variant="outlined"
//                 />
//               </FormControl>
//             </Grid>

//             <Grid item xs={6}>
//               <InputLabel mt={2} className={classes.inputLabels}>
//                 Title:
//               </InputLabel>
//               <FormControl sx={{ width: "100%" }}>
//                 <TextField
//                   fullWidth
//                   id="title"
//                   name="title"
//                   value={formik.values.title}
//                   onChange={formik.handleChange}
//                   error={formik.touched.title && Boolean(formik.errors.title)}
//                   helperText={formik.touched.title && formik.errors.title}
//                   variant="outlined"
//                 />
//               </FormControl>
//             </Grid>
//             {/* Image upload section */}
//             <Grid item xs={6}>
//               <InputLabel mt={2} className={classes.inputLabels}>
//                 Image:
//               </InputLabel>
//               <FormControl sx={{ width: "100%" }}>
//                 <input
//                   type="file"
//                   accept="image/*"
//                   onChange={handleImageChange}
//                   style={{ display: "none" }}
//                   id="image-upload"
//                 />
//                 <label htmlFor="image-upload">
//                   <Button
//                     variant="outlined"
//                     component="span"
//                     sx={{
//                       height: "55px",
//                       textTransform: "none",
//                       color: "black",
//                     }}
//                   >
//                     Upload Image
//                   </Button>
//                 </label>
//                 {/* Display image preview */}
//                 {imagePreview && (
//                   <Box
//                     mt={2}
//                     position="relative"
//                     sx={{ display: "inline-block", position: "relative" }}
//                   >
//                     <div
//                       style={{ position: "relative", display: "inline-block" }}
//                     >
//                       <img
//                         src={imagePreview}
//                         alt="Uploaded preview"
//                         width={100}
//                         height={100}
//                         style={{ display: "block" }}
//                       />
//                       <IconButton
//                         onClick={handleImageDelete}
//                         sx={{
//                           position: "absolute",
//                           top: "-8px",
//                           right: "-10px",
//                           color: "grey",
//                         }}
//                       >
//                         <DeleteIcon />
//                       </IconButton>
//                     </div>
//                   </Box>
//                 )}
//                 {!imagePreview &&
//                   isEditMode &&
//                   service &&
//                   service.data.image && (
//                     <Box
//                       mt={2}
//                       position="relative"
//                       sx={{ display: "inline-block" }}
//                     >
//                       <img
//                         src={`${process.env.REACT_APP_BASE_URL}/images/${service.data.image}`} // Adjust base URL
//                         alt="Uploaded preview"
//                         width={100}
//                         height={100}
//                         style={{ display: "block" }}
//                       />
//                     </Box>
//                   )}

//                 <Typography variant="caption" color="black" ml={1}>
//                   {imagePreview && "Image uploaded successfully"}
//                 </Typography>
//               </FormControl>
//             </Grid>
//             <Grid item xs={12}>
//               <InputLabel mt={2} className={classes.inputLabels}>
//                 Description:
//               </InputLabel>
//               <CKEditor
//                 editor={ClassicEditor}
//                 data={formik.values.description}
//                 onReady={(editor) => {
//                   editor.ui.view.editable.element.style.height = "400px";
//                 }}
//                 config={{
//                   ckfinder: {
//                     uploadUrl:
//                       "https://api.zahrasalonandspa.ca/api/service/upload-image",
//                   },
//                 }}
//                 onChange={(event, editor) => {
//                   const data = editor.getData();
//                   setEditorState(data);
//                   formik.setFieldValue("description", data);
//                 }}
//               />
//             </Grid>
//           </Grid>
//         </Box>
//       </form>
//     </Box>
//   );
// };

// export default AddServiceDetails;

// const AddServiceDetails = () => {
//   const navigate = useNavigate();
//   const { id } = useParams();
//   // console.log("Service Id", id);

//   const isEditMode = !!id;
//   const [searchParams] = useSearchParams();

//   const [media, setMedia] = useState(null);
//   const [mediaPreview, setMediaPreview] = useState(null);
//   const [mediaType, setMediaType] = useState(null);
//   const [editorState, setEditorState] = useState("");
//   const [service, setService] = useState(null);

//   const validationSchema = yup.object({
//     serviceId: yup.string().required("Service ID is required"),
//     price: yup.number().positive().required("Price is required"),
//     title: yup.string().required("Title is required"),
//     description: yup.string().required("Description is required"),
//   });

//   useEffect(() => {
//     if (searchParams.get("serviceId") !== null) {
//       // Fetch existing service details
//       ServicesApi.getServiceDetailById(id).then((res) => {
//         const serviceData = res.data;
//         setService(serviceData);
//         formik.setFieldValue("price", serviceData.data.price);
//         formik.setFieldValue("title", serviceData.data.title);
//         formik.setFieldValue("description", serviceData.data.description);
//         formik.setFieldValue("image", serviceData.data.image);
//       });
//     }
//   }, [id]);

//   const formik = useFormik({
//     initialValues: {
//       serviceId: id,
//       price: "",
//       title: "",
//       description: "",
//       image: "",
//     },
//     validationSchema: validationSchema,
//     onSubmit: (values) => {
//       console.log({ values });
//       const formData = new FormData();
//       formData.append("serviceId", values.serviceId);
//       formData.append("price", values.price);
//       formData.append("title", values.title);
//       formData.append("description", values.description);

//       if (values.image instanceof File) {
//         formData.append("file", values.image);
//       }

//       let serviceID = searchParams.get("serviceId");
//       console.log("serviceID", serviceID);

//       if (serviceID) {
//         ServicesApi.updateServiceDetails(id, formData).then(() => {
//           navigate(`/services/detail/${serviceID}`, { replace: true });
//         });
//       } else {
//         ServicesApi.addServicesDetail(formData).then(() => {
//           console.log("payload", formData);
//           navigate(`/services/detail/${id}`, { replace: true });
//         });
//       }
//     },
//   });

//   const handleMediaChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setMedia(file);
//       setMediaPreview(URL.createObjectURL(file));
//       setMediaType(file.type.startsWith("image/") ? "image" : "video");
//       formik.setFieldValue("image", file);
//     }
//   };

//   const handleMediaDelete = () => {
//     setMedia(null);
//     setMediaPreview(null);
//     setMediaType(null);
//     formik.setFieldValue("image", null);
//   };

//   return (
//     <Box
//       sx={{
//         background: "#FFFFFF",
//         borderRadius: "14px",
//         padding: "30px",
//       }}
//     >
//       <form onSubmit={formik.handleSubmit}>
//         <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//           <Box display={"flex"}>
//             <ArrowBackIcon
//               onClick={() => {
//                 let backId =
//                   searchParams.get("serviceId") !== null
//                     ? searchParams.get("serviceId")
//                     : id;
//                 navigate(`/services/detail/${backId}`, { replace: true });
//               }}
//             />
//             <Typography ml={1} sx={{ fontWeight: "600", fontSize: "16px" }}>
//               {searchParams.get("serviceId") !== null ? "Edit" : "Add"} Service
//               Details
//             </Typography>
//           </Box>
//           <Box pt={3}>
//             <Button
//               type="submit"
//               variant="contained"
//               sx={{
//                 fontWeight: 500,
//                 fontSize: "14px",
//                 background: "secondary.main",
//                 borderRadius: "5px",
//                 border: "none",
//                 boxShadow: "none",
//                 color: "#FFFFFF",
//                 outline: "none",
//                 "&:hover": {
//                   background: "secondary.main",
//                   outline: "none",
//                 },
//               }}
//             >
//               Save
//             </Button>
//           </Box>
//         </Box>

//         <Box>
//           <Grid container spacing={2}>
//             <Grid item xs={6}>
//               <InputLabel mt={2} className="inputLabels">
//                 Price:
//               </InputLabel>
//               <FormControl sx={{ width: "100%" }}>
//                 <TextField
//                   fullWidth
//                   id="price"
//                   name="price"
//                   type="number"
//                   value={formik.values.price}
//                   onChange={formik.handleChange}
//                   error={formik.touched.price && Boolean(formik.errors.price)}
//                   helperText={formik.touched.price && formik.errors.price}
//                   variant="outlined"
//                 />
//               </FormControl>
//             </Grid>

//             <Grid item xs={6}>
//               <InputLabel mt={2} className="inputLabels">
//                 Title:
//               </InputLabel>
//               <FormControl sx={{ width: "100%" }}>
//                 <TextField
//                   fullWidth
//                   id="title"
//                   name="title"
//                   value={formik.values.title}
//                   onChange={formik.handleChange}
//                   error={formik.touched.title && Boolean(formik.errors.title)}
//                   helperText={formik.touched.title && formik.errors.title}
//                   variant="outlined"
//                 />
//               </FormControl>
//             </Grid>

//             {/* Media upload section (Single uploader for both image and video) */}
//             <Grid item xs={6}>
//               <InputLabel mt={2} className="inputLabels">
//                 Upload Media:
//               </InputLabel>
//               <FormControl sx={{ width: "100%" }}>
//                 <input
//                   type="file"
//                   accept="image/*, video/*"
//                   onChange={handleMediaChange}
//                   style={{ display: "none" }}
//                   id="media-upload"
//                   name="image"
//                 />
//                 <label htmlFor="media-upload">
//                   <Button
//                     variant="outlined"
//                     component="span"
//                     sx={{
//                       height: "55px",
//                       textTransform: "none",
//                       color: "black",
//                     }}
//                   >
//                     Upload Media
//                   </Button>
//                 </label>
//                 {/* Display media preview */}
//                 {mediaPreview && mediaType === "image" && (
//                   <Box
//                     mt={2}
//                     position="relative"
//                     sx={{ display: "inline-block" }}
//                   >
//                     <img
//                       src={mediaPreview}
//                       alt="Uploaded preview"
//                       width={100}
//                       height={100}
//                       style={{ display: "block" }}
//                     />
//                     <IconButton
//                       onClick={handleMediaDelete}
//                       sx={{
//                         position: "absolute",
//                         top: "-8px",
//                         right: "-10px",
//                         color: "grey",
//                       }}
//                     >
//                       <DeleteIcon />
//                     </IconButton>
//                   </Box>
//                 )}
//                 {mediaPreview && mediaType === "video" && (
//                   <Box
//                     mt={2}
//                     position="relative"
//                     sx={{ display: "inline-block" }}
//                   >
//                     <video width="100" height="100" controls>
//                       <source src={mediaPreview} />
//                     </video>
//                     <IconButton
//                       onClick={handleMediaDelete}
//                       sx={{
//                         position: "absolute",
//                         top: "-8px",
//                         right: "-10px",
//                         color: "grey",
//                       }}
//                     >
//                       <DeleteIcon />
//                     </IconButton>
//                   </Box>
//                 )}
//                 {!mediaPreview &&
//                   isEditMode &&
//                   service &&
//                   service.data.media && (
//                     <Box
//                       mt={2}
//                       position="relative"
//                       sx={{ display: "inline-block" }}
//                     >
//                       {service.data.media &&
//                       service.data.media.type.startsWith("image") ? (
//                         <img
//                           src={`${process.env.REACT_APP_BASE_URL}/images/${service.data.media}`} // Adjust base URL
//                           alt="Uploaded preview"
//                           width={100}
//                           height={100}
//                         />
//                       ) : (
//                         <video width="100" height="100" controls>
//                           <source
//                             src={`${process.env.REACT_APP_BASE_URL}/images/${service.data.media}`} // Adjust base URL
//                           />
//                         </video>
//                       )}
//                     </Box>
//                   )}
//               </FormControl>
//             </Grid>
//           </Grid>

//           {/* Description */}
//           <Grid item xs={12}>
//             <InputLabel mt={2} className="inputLabels">
//               Description:
//             </InputLabel>
//             <CKEditor
//               editor={ClassicEditor}
//               data={editorState}
//               onChange={(event, editor) => {
//                 const data = editor.getData();
//                 setEditorState(data);
//                 formik.setFieldValue("description", data);
//               }}
//             />
//           </Grid>
//         </Box>
//       </form>
//     </Box>
//   );
// };

// export default AddServiceDetails;

const AddServiceDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditMode = !!id;
  const [searchParams] = useSearchParams();

  const [media, setMedia] = useState(null);
  const [mediaPreview, setMediaPreview] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [editorState, setEditorState] = useState("");
  const [service, setService] = useState(null);

  const validationSchema = yup.object({
    serviceId: yup.string().required("Service ID is required"),
    price: yup.number().positive().required("Price is required"),
    title: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),
  });

  useEffect(() => {
    const serviceDetailsId = searchParams.get("serviceId");

    if (serviceDetailsId !== null) {
      ServicesApi.getServiceDetailById(id)
        .then((res) => {
          const serviceData = res.data;
          console.log("API Response:", serviceData);

          if (serviceData && serviceData.data) {
            const { title, description, price, image } = serviceData.data;

            // Set form fields for service details
            setService(serviceData);
            formik.setFieldValue("price", price || "");
            formik.setFieldValue("title", title || "");
            formik.setFieldValue("description", description || "");

            // Set the CKEditor description
            setEditorState(description || "");

            // Handle media (specifically from service details, not service)
            const imageFile = image ? image.replace(/"/g, "") : null; // Clean quotes
            if (imageFile) {
              setMediaPreview(
                `${process.env.REACT_APP_BASE_URL}/images/${imageFile}`
              );
              setMediaType("image");
            } else {
              setMediaPreview(null); // No media to preview
              setMediaType(null);
            }
          } else {
            console.error("No valid service details found.");
          }
        })
        .catch((error) => {
          console.error("Error fetching service details:", error);
        });
    }
  }, [id, searchParams]);

  const formik = useFormik({
    initialValues: {
      serviceId: id || "",
      price: "",
      title: "",
      description: "",
      image: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("serviceId", values.serviceId);
      formData.append("price", values.price);
      formData.append("title", values.title);
      formData.append("description", values.description);

      if (values.image instanceof File) {
        formData.append("file", values.image);
      }

      const serviceID = searchParams.get("serviceId");
      if (serviceID) {
        ServicesApi.updateServiceDetails(id, formData).then(() => {
          navigate(`/services/detail/${serviceID}`, { replace: true });
        });
      } else {
        ServicesApi.addServicesDetail(formData).then(() => {
          navigate(`/services/detail/${id}`, { replace: true });
        });
      }
    },
  });

  const handleMediaChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setMedia(file);
      setMediaPreview(URL.createObjectURL(file));
      setMediaType(file.type.startsWith("image/") ? "image" : "video");
      formik.setFieldValue("image", file);
    }
  };

  const handleMediaDelete = () => {
    setMedia(null);
    setMediaPreview(null);
    setMediaType(null);
    formik.setFieldValue("image", null);
  };

  return (
    // <Box
    //   sx={{
    //     background: "#FFFFFF",
    //     borderRadius: "14px",
    //     padding: "30px",
    //   }}
    // >
    //   <form onSubmit={formik.handleSubmit}>
    //     <Box sx={{ display: "flex", justifyContent: "space-between" }}>
    //       <Box display="flex">
    //         <ArrowBackIcon
    //           onClick={() => {
    //             const backId = searchParams.get("serviceId") || id;
    //             navigate(`/services/detail/${backId}`, { replace: true });
    //           }}
    //         />
    //         <Typography ml={1} sx={{ fontWeight: "600", fontSize: "16px" }}>
    //           {isEditMode ? "Edit" : "Add"} Service Details
    //         </Typography>
    //       </Box>
    //       <Box pt={3}>
    //         <Button
    //           type="submit"
    //           variant="contained"
    //           sx={{
    //             fontWeight: 500,
    //             fontSize: "14px",
    //             background: "secondary.main",
    //             borderRadius: "5px",
    //             boxShadow: "none",
    //             color: "#FFFFFF",
    //             "&:hover": {
    //               background: "secondary.main",
    //             },
    //           }}
    //         >
    //           Save
    //         </Button>
    //       </Box>
    //     </Box>

    //     <Grid container spacing={2}>
    //       <Grid item xs={6}>
    //         <InputLabel>Price:</InputLabel>
    //         <FormControl sx={{ width: "100%" }}>
    //           <TextField
    //             fullWidth
    //             id="price"
    //             name="price"
    //             type="number"
    //             value={formik.values.price}
    //             onChange={formik.handleChange}
    //             error={formik.touched.price && Boolean(formik.errors.price)}
    //             helperText={formik.touched.price && formik.errors.price}
    //             variant="outlined"
    //           />
    //         </FormControl>
    //       </Grid>

    //       <Grid item xs={6}>
    //         <InputLabel>Title:</InputLabel>
    //         <FormControl sx={{ width: "100%" }}>
    //           <TextField
    //             fullWidth
    //             id="title"
    //             name="title"
    //             value={formik.values.title}
    //             onChange={formik.handleChange}
    //             error={formik.touched.title && Boolean(formik.errors.title)}
    //             helperText={formik.touched.title && formik.errors.title}
    //             variant="outlined"
    //           />
    //         </FormControl>
    //       </Grid>

    //       <Grid item xs={6}>
    //         <InputLabel>Upload Media:</InputLabel>
    //         <FormControl sx={{ width: "100%" }}>
    //           <input
    //             type="file"
    //             accept="image/*, video/*"
    //             onChange={handleMediaChange}
    //             style={{ display: "none" }}
    //             id="media-upload"
    //           />
    //           <label htmlFor="media-upload">
    //             <Button
    //               variant="outlined"
    //               component="span"
    //               sx={{ height: "55px", textTransform: "none" }}
    //             >
    //               Upload Media
    //             </Button>
    //           </label>
    //           {mediaPreview && mediaType === "image" && (
    //             <Box mt={2} position="relative">
    //               <img
    //                 src={mediaPreview}
    //                 alt="Uploaded preview"
    //                 width={100}
    //                 height={100}
    //               />
    //               <IconButton
    //                 onClick={handleMediaDelete}
    //                 sx={{ position: "absolute", top: "-8px", right: "-10px" }}
    //               >
    //                 <DeleteIcon />
    //               </IconButton>
    //             </Box>
    //           )}
    //           {mediaPreview && mediaType === "video" && (
    //             <Box mt={2} position="relative">
    //               <video width="100" height="100" controls>
    //                 <source src={mediaPreview} />
    //               </video>
    //               <IconButton
    //                 onClick={handleMediaDelete}
    //                 sx={{ position: "absolute", top: "-8px", right: "-10px" }}
    //               >
    //                 <DeleteIcon />
    //               </IconButton>
    //             </Box>
    //           )}
    //         </FormControl>
    //       </Grid>

    //       <Grid item xs={12}>
    //         <InputLabel>Description:</InputLabel>
    //         <CKEditor
    //           editor={ClassicEditor}
    //           data={editorState}
    //           onChange={(event, editor) => {
    //             const data = editor.getData();
    //             setEditorState(data);
    //             formik.setFieldValue("description", data);
    //           }}
    //         />
    //       </Grid>
    //     </Grid>
    //   </form>
    // </Box>

    <Box
      sx={{
        background: "#FFFFFF",
        borderRadius: "14px",
        padding: "30px",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box display="flex">
            <ArrowBackIcon
              onClick={() => {
                const backId = searchParams.get("serviceId") || id;
                navigate(`/services/detail/${backId}`, { replace: true });
              }}
            />
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "16px" }}>
              {isEditMode ? "Edit" : "Add"} Service Details
            </Typography>
          </Box>
          <Box pt={3}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                background: "secondary.main",
                borderRadius: "5px",
                boxShadow: "none",
                color: "#FFFFFF",
                "&:hover": {
                  background: "secondary.main",
                },
              }}
            >
              Save
            </Button>
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputLabel>Price:</InputLabel>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                fullWidth
                id="price"
                name="price"
                type="number"
                value={formik.values.price}
                onChange={formik.handleChange}
                error={formik.touched.price && Boolean(formik.errors.price)}
                helperText={formik.touched.price && formik.errors.price}
                variant="outlined"
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <InputLabel>Title:</InputLabel>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                fullWidth
                id="title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                variant="outlined"
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <InputLabel>Upload Media:</InputLabel>
            <FormControl sx={{ width: "100%" }}>
              <input
                type="file"
                accept="image/*, video/*"
                onChange={handleMediaChange}
                style={{ display: "none" }}
                id="media-upload"
              />
              <label htmlFor="media-upload">
                <Button
                  variant="outlined"
                  component="span"
                  sx={{ height: "55px", textTransform: "none" }}
                >
                  Upload Media
                </Button>
              </label>

              {/* Media Preview */}
              {mediaPreview && mediaType === "image" && (
                <Box mt={2} position="relative">
                  <img
                    src={mediaPreview}
                    alt="Uploaded preview"
                    className="w-full h-auto"
                  />
                  <IconButton
                    onClick={handleMediaDelete}
                    sx={{ position: "absolute", top: "-8px", right: "-10px" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
              {mediaPreview && mediaType === "video" && (
                <Box mt={2} position="relative">
                  <video controls className="w-full h-auto">
                    <source src={mediaPreview} />
                  </video>
                  <IconButton
                    onClick={handleMediaDelete}
                    sx={{ position: "absolute", top: "-8px", right: "-10px" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <InputLabel>Description:</InputLabel>
            <CKEditor
              editor={ClassicEditor}
              data={editorState}
              onChange={(event, editor) => {
                const data = editor.getData();
                setEditorState(data);
                formik.setFieldValue("description", data);
              }}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AddServiceDetails;
