import React, { useState, useEffect } from "react";

import {
  Grid,
  Typography,
  Box,
  ButtonGroup,
  Button,
  Modal,
  Backdrop,
  Fade,
  IconButton,
  Checkbox,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.css";
import { ServicesApi } from "../../../apis";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
//
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import axios from "axios";

function ServiceDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isPromoted, setIsPromoted] = useState(ServicesApi.isPromoted);

  const [selectedImages, setSelectedImages] = useState([]);
  const [descriptionPreview, setDescription] = useState("");
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState(null);
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const getServicesDetail = async () => {
    try {
      const response = await ServicesApi.getServicesDetail(id);
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching service details:", error);
    }
  };
  useEffect(() => {
    getServicesDetail();
  }, []);

  const handlePreview = (description) => {
    console.log(description);
    setDescription(description);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    ServicesApi.deleteServiceDetails(id)
      .then(() => {
        getServicesDetail();
        // console.log(`Service with ID ${id} deleted successfully.`);
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
      });
  };

  const handleCheckboxChange = async (service) => {
    const updatedStatus = !service.isPromoted;

    const data = {
      ...service,
      isPromoted: updatedStatus,
    };

    console.log("Sending updated data to API:", data);

    try {
      const res = await ServicesApi.updateServiceDetails(service.id, data);
      console.log("API response:", res);

      setData((prevData) =>
        prevData.map((serviceItem) =>
          serviceItem.id === service.id
            ? { ...serviceItem, isPromoted: updatedStatus }
            : serviceItem
        )
      );
    } catch (error) {
      console.error("Error updating service details:", error);
      toast.error(error.message || "Error updating service details");
    }
  };

  return (
    <Box
      sx={{
        background: "#FFFFFF",
        borderRadius: "14px",
        padding: "30px",
      }}
    >
      <div className="table-responsive" style={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box display={"flex"}>
            <ArrowBackIcon onClick={() => navigate("/services")} />
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "16px" }}>
              View Service Details
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={() => navigate(`/add-service-detail/${id}`)}
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              background: "secondary.main",
              borderRadius: "5px",
              border: "none",
              boxShadow: "none",
              color: "#FFFFFF",
              outline: "none",
              "&:hover": {
                background: "secondary.main",
                outline: "none",
              },
            }}
          >
            Add Service Details
          </Button>
        </Box>
        <Grid container spacing={2} pt={5}>
          <Grid xs={12}>
            <table className="table">
              <thead>
                <tr>
                  <th style={{ paddingRight: "10px" }}>Service ID</th>
                  <th style={{ paddingRight: "10px" }}>Image</th>
                  <th style={{ paddingLeft: "40px" }}>Title</th>
                  <th style={{ paddingLeft: "40px" }}>Price</th>

                  <th style={{ paddingLeft: "40px" }}>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.length === 0 && (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "left" }}>
                      No data found
                    </td>
                  </tr>
                )}

                {/* {data &&
                  data.map((service, index) => (
                    <tr key={service.id}>
                      <td style={{ paddingLeft: "40px" }}>{service.id}</td>
                      <td>
                        {console.log(
                          `${process.env.REACT_APP_BASE_URL}/images/${
                            service.image ? service.image.replace(/"/g, "") : ""
                          }`
                        )}
                        {console.log(
                          "gh",
                          service.image && service.image.endsWith(".mp4")
                        )}

                        {service.image && service.image.endsWith(".mp4") ? (
                          <video
                            controls
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                          >
                            <source
                              src={`${
                                process.env.REACT_APP_BASE_URL
                              }/images/${service.image.replace(/"/g, "")}`}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          service.image && (
                            <img
                              src={`${
                                process.env.REACT_APP_BASE_URL
                              }/images/${service.image.replace(/"/g, "")}`}
                              alt={service.title}
                              style={{ maxWidth: "100px", maxHeight: "100px" }}
                            />
                          )
                        )}
                      </td>
                      <td style={{ paddingLeft: "40px" }}>{service.title}</td>
                      <td style={{ paddingLeft: "40px" }}>{service.price}</td>
                      <td style={{ paddingLeft: "40px" }}>
                        <div
                          style={{
                            maxWidth: "200px",
                            overflowX: "hidden",
                            overflowY: "hidden",
                            textOverflow: "scroll",
                            height: "200px",
                            hyphens: "auto",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: service.description,
                          }}
                        ></div>
                      </td>
                      <td>
                        <ButtonGroup
                          variant="text"
                          aria-label="large button group"
                          sx={{ paddingLeft: "auto" }}
                        >
                          <Tooltip title="Add">
                            <Button
                              style={{ color: "grey" }}
                              onClick={() =>
                                navigate(
                                  `/add-service-detail/${service.id}?serviceId=${service.serviceId}`
                                )
                              }
                            >
                              <BorderColorIcon />
                            </Button>
                          </Tooltip>
                          <Tooltip title="delete">
                            <Button onClick={() => handleDelete(service.id)}>
                              <DeleteIcon color="error" />
                            </Button>
                          </Tooltip>
                          <Tooltip title="view">
                            <Button
                              onClick={() => handlePreview(service.description)}
                              style={{ color: "green" }}
                            >
                              <VisibilityIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip title="Promote">
                            <Checkbox
                              type="checkbox"
                              name="isPromoted"
                              checked={service.isPromoted}
                              onChange={() => handleCheckboxChange(service)}
                            />
                          </Tooltip>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))} */}
                {data &&
                  data.map((service, index) => (
                    <tr key={service.id}>
                      <td style={{ paddingLeft: "40px" }}>{service.id}</td>
                      <td>
                        {/* Log the constructed URL to check it */}
                        {console.log(
                          `${process.env.REACT_APP_BASE_URL}/images/${
                            service.image ? service.image : ""
                          }`
                        )}
                        {console.log(
                          "gh",
                          service.image && service.image.endsWith(".mp4")
                        )}

                        {service.image && service.image.endsWith(".mp4") ? (
                          <video
                            controls
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                          >
                            <source
                              src={`${process.env.REACT_APP_BASE_URL}/images/${service.image}`}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          service.image && (
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}/images/${service.image}`}
                              alt={service.title}
                              style={{ maxWidth: "100px", maxHeight: "100px" }}
                            />
                          )
                        )}
                      </td>
                      <td style={{ paddingLeft: "40px" }}>{service.title}</td>
                      <td style={{ paddingLeft: "40px" }}>{service.price}</td>
                      <td style={{ paddingLeft: "40px" }}>
                        {/* Description preview */}
                        <div
                          style={{
                            maxWidth: "200px",
                            overflowX: "hidden",
                            overflowY: "hidden",
                            textOverflow: "scroll",
                            height: "200px",
                            hyphens: "auto",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: service.description,
                          }}
                        ></div>
                      </td>
                      <td>
                        <ButtonGroup
                          variant="text"
                          aria-label="large button group"
                          sx={{ paddingLeft: "auto" }}
                        >
                          <Tooltip title="Add">
                            <Button
                              style={{ color: "grey" }}
                              onClick={() =>
                                navigate(
                                  `/add-service-detail/${service.id}?serviceId=${service.serviceId}`
                                )
                              }
                            >
                              <BorderColorIcon />
                            </Button>
                          </Tooltip>
                          <Tooltip title="delete">
                            <Button onClick={() => handleDelete(service.id)}>
                              <DeleteIcon color="error" />
                            </Button>
                          </Tooltip>
                          <Tooltip title="view">
                            <Button
                              onClick={() => handlePreview(service.description)}
                              style={{ color: "green" }}
                            >
                              <VisibilityIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip title="Promote">
                            <Checkbox
                              type="checkbox"
                              name="isPromoted"
                              checked={service.isPromoted}
                              onChange={() => handleCheckboxChange(service)}
                            />
                          </Tooltip>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Grid>
        </Grid>
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Preview
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div>
            {/* html */}
            <div dangerouslySetInnerHTML={{ __html: descriptionPreview }} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
}

export default ServiceDetails;

//new code
